.seo {
  border: initial;
  color: #353535;
  margin-bottom: 19px;
}

.seo h1, .seo h2, .seo h3, .seo h4 {
  letter-spacing: .3px;
  font-weight: bold;
}

.seo p {
  color: #353535;
  margin: 21px 0 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

.seo ul {
  margin: 21px 0 0 15px;
  list-style-type: disc;
}

.seo ul li {
  margin: 0 0 21px;
  font-size: 14px;
  font-weight: normal;
  line-height: 26px;
  list-style: none;
  position: relative;
}

.seo ol {
  counter-reset: item;
  margin-bottom: 20px;
  padding: 0;
}

.seo ol li {
  color: #353535;
  margin: 0 0 21px;
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  position: relative;
}

.seo ol li:last-child {
  margin: 0;
}

.seo ol li ol {
  margin-top: 5px;
  padding-left: 15px;
}

.seo ol li ol li::marker {
  content: "";
}

.seo a {
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
}

.seo__title {
  margin: 0 0 12px;
}

.seo-container-v2 section {
  background-color: #fff;
  padding: 0;
}

.seo-container-v2 section h1, .seo-container-v2 section h2, .seo-container-v2 section h3 {
  color: #606060;
  margin: 0;
  padding: 21px 40px 21px 0;
  font-size: 21px;
  line-height: 1.4em;
  position: relative;
}

@media (width <= 1023px) {
  .seo-container-v2 section h1, .seo-container-v2 section h2, .seo-container-v2 section h3 {
    font-size: 18px;
  }
}

.seo-container-v2 section h1:after, .seo-container-v2 section h2:after, .seo-container-v2 section h3:after {
  content: "";
  cursor: pointer;
  background-image: url("/img/arr_select.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 14px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)rotate(0);
}

.seo-container-v2 section h1 {
  padding: 0 40px 20px 0;
}

.seo-container-v2 section h1:after {
  top: calc(50% - 10px);
}

.seo-container-v2 section div {
  box-sizing: border-box;
  border-bottom: 3px solid #e9e9e9;
  transition: max-height .15s;
  overflow: hidden;
}

.seo-container-v2 section div * {
  color: #0000;
}

.seo-container-v2 section.is-open h1:after, .seo-container-v2 section.is-open h2:after, .seo-container-v2 section.is-open h3:after {
  transform: translateY(-50%)rotate(180deg);
}

.seo-container-v2 section.is-open div {
  max-height: 2000px;
  padding-bottom: 21px;
}

.seo-container-v2 section.is-open div * {
  color: #353535;
}

.seo-container-v2 section.is-open div p:first-child {
  margin-top: 0;
}

.seo-container-v2 section.is-open strong {
  color: #353535;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
}

.seo-container-v2 section.is-open ul li:before {
  content: " ";
  background: #666;
  border-radius: 50px;
  align-items: center;
  width: 10px;
  height: 10px;
  position: absolute;
  transform: translate(-14px, 8px);
}

.seo-container-v2 section.is-open ul li strong {
  line-height: 17px;
}

.seo-container-v2 section.is-open p a {
  color: #c7a4fd;
}

.seo-container-v2 section:not(.is-open) div {
  max-height: 0;
}
/*# sourceMappingURL=index.0a7a1c61.css.map */
