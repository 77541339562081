$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
@import "base/colors";
.seo{
  border: initial;
  color: $blackH;
  margin-bottom: 19px;
  & h1, h2, h3, h4{
    font-weight: bold;
    letter-spacing: 0.3px;
  }
  & p {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    color: $blackH;
    margin: 21px 0 0 0;
  }
  & ul {
    margin: 21px 0 0 15px;
    list-style-type: disc;
    li {
      position: relative;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      margin: 0 0 21px 0;
      list-style: none;
    }
  }
  & ol {
    counter-reset: item;
    padding: 0;
    margin-bottom: 20px;
    & li {
      position: relative;
      color: $blackH;
      font-size: 14px;
      font-weight: 300;
      line-height: 26px;
      margin: 0 0 21px 0;
      &:last-child {
        margin: 0;
      }
      & ol {
        margin-top: 5px;
        padding-left: 15px;
        & li{
          &::marker{
            content: "";
          }
        }
      }
    }
  }

  & a {
    font-size: 13px;
    font-weight: 400;
    text-decoration: underline;
  }
  &__title {
    margin: 0 0 12px;
  }
  &-container-v2{
    & section {
      padding: 0;
      background-color: #fff;
      & h1, h2, h3 {
        padding: 21px 40px 21px 0;
        position: relative;
        margin: 0;
        color: $grey;
        font-size: 21px;
        line-height: 1.4em;
        @media (max-width: 1023px) {
          font-size: 18px;
        }
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          width: 20px;
          height: 14px;
          background-image: url(/img/arr_select.svg);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
          transform: translateY(-50%) rotate(0deg);
          transition: .3s;
        }
      }
      & h1{
        padding: 0 40px 20px 0;
        &:after{
          top: calc(50% - 10px);
        }
      }
      & div {
        transition: max-height .15s;
        border-bottom: 3px solid #E9E9E9;
        box-sizing: border-box;
        overflow: hidden;
        & *{
          color: transparent;
        }
      }
      &.is-open {
        & h1, h2, h3 {
          &:after{
            transform: translateY(-50%) rotate(180deg);
          }
        }
        & div {
          max-height: 2000px;
          padding-bottom: 21px;
          & *{
            color: $blackH;
          }
          & p:nth-child(1){
            margin-top: 0;
          }
        }
        & strong{
          font-weight: bold;
          font-size: 14px;
          line-height: 26px;
          color:$blackH;
        }
        & ul{
          & li{
            &::before{
              content: "\00A0";
              width: 10px;
              height: 10px;
              background: #666666;
              border-radius: 50px;
              position: absolute;
              transform: translate(-14px, 8px);
              align-items: center;
            }
            strong{
              line-height: 17px;
            }
          }
        }
        & p{
          & a{
            color: #c7a4fd;
          }
        }
      }
      &:not(.is-open) {
        & div {
          max-height: 0;
        }
      }
    }
  }
}
